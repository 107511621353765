import React from "react"
import CommunityLayout from "../../components/layouts/communityLayout"

const CommunityIndexPage = props => (
  <CommunityLayout>
    <h1>About Us</h1>h
  </CommunityLayout>
)

export default CommunityIndexPage
